/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import Slider from "react-slick";

import homeBannerImg from "../../../common/images/banner.jpg";
import { GET_BANNER_LIST } from "../../../actions";

var Parser = require("html-react-parser");

class HomeBanner extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.getBannerList();
  }

  componentDidMount() {
    /*console.log('DidMount');*/
  }

  /* componentDidUpdate() {
		console.log('this called last - after page loading');
	  } */

  render() {
    let bannerArr = this.props.banner;
    let bannerlist = [];
    let bannerimagesource = "";

    if (Object.keys(bannerArr).length > 0) {
      if (bannerArr[0].status === "ok") {
        bannerlist = bannerArr[0].result_set;
        bannerimagesource = bannerArr[0].common.image_source;
      }
    }

    var settingsGallery = {
      /*  infinite: true, */
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      adaptiveHeight: true,
      /* speed: 5000, */
      dots: true,
    };

    return (
      <div className="home-banner">
        {Object.keys(bannerlist).length > 0 ? (
          <Slider {...settingsGallery}>
            {bannerlist.map((banner, index) => {
              return (
                <div key={index}>
                  <a
                    href={
                      banner.banner_link !== "" && banner.banner_link !== null
                        ? banner.banner_link
                        : void 0
                    }
                    target={
                      banner.banner_link !== "" && banner.banner_link !== null
                        ? "_blank"
                        : "_self"
                    }
                  >
                    <img
                      src={bannerimagesource + banner.banner_image}
                      alt="Banner"
                    />

                    {banner.banner_description !== "" &&
                    banner.banner_description !== null
                      ? Parser(banner.banner_description)
                      : ""}
                  </a>
                </div>
              );
            })}
          </Slider>
        ) : (
          <div className="loader-main-cls">
            <img src={homeBannerImg} alt="Banner" />
            <div className="loader-sub-div"></div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    banner: state.banner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBannerList: () => {
      dispatch({ type: GET_BANNER_LIST });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(HomeBanner);
